<script setup lang="ts">
const { data: staticKeys } = await useFetchMessages()

const navIsOpen = ref(false)

const dynamicHeaderClass = computed(() => ({
    'page-header-custom-open': navIsOpen.value
}))

const handleNavigationClick = () => navIsOpen.value = !navIsOpen.value
</script>

<template>
    <header class="page-header-custom" :class="dynamicHeaderClass">
        <div class="page-header-custom-inner">
            <p class="page-header-custom-hotline" v-html="staticKeys?.screen2save.hotline" />
            <div class="page-header-custom-burger" @click="handleNavigationClick">
                <span v-for="index in 3" :key="`line-${index}`" class="line" />
            </div>
        </div>
    </header>
</template>

<style lang="scss">
.page-header-custom {
    position: fixed;
    top: 0;
    align-items: center;
    z-index: z('page-header');
    transition: transform 0.5s ease-in-out;
    @include fullbleed;

    &::before {
        content: '';
        z-index: z('page-header-gradient');
        transition: opacity 0.5s ease-in-out;
        pointer-events: none;
        background: $body-bg-gradient-top;
        height: 100%;
        position: absolute;
        inset: 0;
    }
}

.page-header-custom-inner {
    grid-column: 2 / -2;
    position: relative;
    z-index: z('page-header-inner');
    padding-block: $page-grid-gap-mobile;
    display: flex;
    justify-content: space-between;
}

.page-header-custom-burger {
    width: px2rem(40);
    height: px2rem(40);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-end;

    .line {
        height: 2px;
        background-color: $white;
        display: block;
        margin-block: 2.5px;
        transition: all 0.3s ease-in-out;

        &:nth-child(1) {
            width: px2rem(18);
        }

        &:nth-child(2) {
            width: px2rem(24);
        }

        &:nth-child(3) {
            width: px2rem(10);
        }
    }
}

.page-header-custom-open  {
    .page-header-custom-burger {
        .line {
            background-color: $white;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                transition-delay: 0.2s;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(1) {
                transform: translateY(6px) rotate(45deg);
                width: px2rem(18);
            }

            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
                width: px2rem(18);
            }
        }
    }
}

.page-header-custom-hotline {
    @include subline($size1: 12, $size2: 14, $margin1: 0, $margin2: 0, $lineheight: 1);

    a {
        color: currentcolor;
        text-decoration: none;
    }
}
</style>
